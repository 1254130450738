import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {account, changepassword, login} from './auth.api';
import {IUser} from "../../../shared/model/user.model";

interface IInitialLoginState {
    loading: boolean;
    errorMessage: string | null;
    user: IUser | null;
    loginSuccess: boolean;
    changePasswordSuccess: boolean;
    getAccountSuccess: boolean;
    token: string | null;
}

const initialState: IInitialLoginState = {
    loading: false,
    errorMessage: null,
    loginSuccess: false,
    changePasswordSuccess: false,
    getAccountSuccess: false,
    token: null,
    user: null,
};

const {actions, reducer} = createSlice({
    name: 'authenticationSlice',
    initialState,
    reducers: {
        fetching(state) {
            state.loading = true;
        },
        logout(state) {
            state.token = null;
            state.user = null;
            localStorage.removeItem('authentication_token');
        },
        resetAll(state) {
            state.loading = false;
            state.loginSuccess = false;
            state.getAccountSuccess = false;
            state.token = null;
            state.user = null;
            state.errorMessage = null;
        },
        resetEntity(state) {
            state.getAccountSuccess = false;
            state.loginSuccess = false;
            state.loading = false;
            state.errorMessage = null;
        },
        resetChangePassword(state) {
            state.changePasswordSuccess = false;
            state.errorMessage = null;
        },
    },
    extraReducers: {
        [login.fulfilled.type]: (state, {payload}: PayloadAction<{ access_token: string }>) => {
            localStorage.setItem('authentication_token', payload.access_token);
            state.token = payload.access_token;
            state.loginSuccess = true;
            state.loading = false;
        },
        [login.rejected.type]: (state, {payload}: PayloadAction<any>) => {
            localStorage.removeItem('authentication_token');
            state.errorMessage = payload?.message;
            state.loading = false;
            state.loginSuccess = false;
        },
        [account.fulfilled.type]: (state, {payload}: PayloadAction<IUser>) => {
            state.user = payload;
            state.getAccountSuccess = true;
            state.errorMessage = null;
            state.loading = false;
        },
        [account.rejected.type]: (state, {payload}) => {
            localStorage.removeItem('authentication_token');
            state.getAccountSuccess = false;
            state.loading = false;
        },
        [changepassword.fulfilled.type]: (state, {payload}: PayloadAction<any>) => {
            state.changePasswordSuccess = true;
            state.errorMessage = null;
            state.loading = false;
        },
        [changepassword.rejected.type]: (state, {payload}) => {
            state.changePasswordSuccess = false;
            state.errorMessage = 'changepasswordfail';
            state.loading = false;
        },
    },
});

export const {fetching, resetAll, resetEntity, logout, resetChangePassword} = actions;
export default reducer;
