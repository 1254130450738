import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { RootState } from '../../../reducers';
import { IInitialState, IResponse } from '../../../shared/shared-interfaces';
import {createEntity, getEntities, getEntity, getSiteActive, removeEntity, updateEntity} from './group.api';
import {IGroup} from "../../../shared/model/group.model";
import {ISite} from "../../../shared/model/site.model";

export const groupAdapter = createEntityAdapter<IGroup>({
    selectId: ({ group_id }) => group_id,
});

const initialState: IInitialState = {
    activeSites: [],
    fetchEntitiesSuccess: false,
    fetchEntitySuccess: false,
    createEntitySuccess: false,
    updateEntitySuccess: false,
    deleteEntitySuccess: false,
    loading: false,
    errorMessage: null,
    totalItems: 0,
    page: 0,
    size: 0,
    maxPage: 0
};

const { actions, reducer } = createSlice({
    name: 'groupSlice',
    initialState: groupAdapter.getInitialState({ initialState }),
    reducers: {
        fetching(state) {
            state.initialState.loading = true;
        },
        resetAll(state) {
            state.initialState.loading = false;
            state.initialState.fetchEntitiesSuccess = false;
            state.initialState.fetchEntitySuccess = false;
            state.initialState.updateEntitySuccess = false;
            state.initialState.deleteEntitySuccess = false;
            state.initialState.errorMessage = null;
        },
        resetEntity(state) {
            state.initialState.updateEntitySuccess = false;
            state.initialState.errorMessage = null;
            state.initialState.deleteEntitySuccess = false;
        },
    },
    extraReducers: {
        [getEntities.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<IResponse<IGroup[]>>>) => {
            groupAdapter.setAll(state, payload.data.items);
            state.initialState.totalItems = Number(payload.data.totalItems);
            state.initialState.page = Number(payload.data.page);
            state.initialState.size = Number(payload.data.size);
            state.initialState.maxPage = Number(payload.data.maxPage);
            state.initialState.fetchEntitiesSuccess = true;
            state.initialState.loading = false;
        },
        [getEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
            state.initialState.errorMessage = payload?.message;
            state.initialState.loading = false;
            state.initialState.fetchEntitiesSuccess = false;
        },
        [getEntity.fulfilled.type]: (state, { payload }: PayloadAction<IGroup>) => {
            groupAdapter.upsertOne(state, payload);
            state.initialState.fetchEntitySuccess = true;
            state.initialState.loading = false;
        },
        [getEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
            state.initialState.errorMessage = payload?.message;
            state.initialState.loading = false;
            state.initialState.fetchEntitySuccess = false;
        },
        [updateEntity.fulfilled.type]: (state, { payload }: PayloadAction<IGroup>) => {
            groupAdapter.updateOne(state, { id: payload.group_id, changes: payload });
            state.initialState.updateEntitySuccess = true;
            state.initialState.loading = false;
        },
        [updateEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
            state.initialState.errorMessage = payload?.message;
            state.initialState.loading = false;
            state.initialState.updateEntitySuccess = false;
        },
        [createEntity.fulfilled.type]: (state, { payload }: PayloadAction<IGroup>) => {
            groupAdapter.addOne(state, payload);
            state.initialState.createEntitySuccess = true;
            state.initialState.loading = false;
        },
        [createEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
            state.initialState.errorMessage = payload?.message;
            state.initialState.loading = false;
            state.initialState.createEntitySuccess = false;
        },
        [removeEntity.fulfilled.type]: (state, { payload }: PayloadAction<string>) => {
            groupAdapter.removeOne(state, payload);
            state.initialState.totalItems -= 1;
            state.initialState.deleteEntitySuccess = true;
            state.initialState.loading = false;
        },
        [removeEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
            state.initialState.errorMessage = payload?.message;
            state.initialState.loading = false;
            state.initialState.deleteEntitySuccess = false;
        },
        [getSiteActive.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<IResponse<ISite[]>>>) => {
            state.initialState.activeSites = payload.data.items;
            state.initialState.loading = false;
        },
        [getSiteActive.rejected.type]: (state, { payload }: PayloadAction<any>) => {
            state.initialState.errorMessage = payload?.message;
            state.initialState.loading = false;
        }
    },
});

export default reducer;

export const { fetching, resetAll, resetEntity } = actions;
export const groupSelectors = groupAdapter.getSelectors((state: RootState) => state.groupReducer);

const getGroupState = (state: RootState) => state.groupReducer;
const { selectById } = groupAdapter.getSelectors();

export const selectEntityById = (id: string) => {
    return createSelector(getGroupState, (state) => selectById(state, id));
};
