import {cilMenu} from '@coreui/icons-pro';
import CIcon from '@coreui/icons-react';
import {CContainer, CHeader, CHeaderDivider, CHeaderNav, CHeaderToggler} from '@coreui/react-pro';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../reducers';
import AppBreadcrumb from './AppBreadcrumb';
import {toggleSidebar} from './reducer';

const TheHeader = () => {
    const dispatch = useDispatch();
    const {sidebarShow} = useSelector((state: RootState) => state.container);

    const toggleSidebarDesktop = () => {
        dispatch(toggleSidebar(!sidebarShow));
    };

    return (
        <CHeader position="sticky" className="mb-4">
            <CContainer fluid>
                <CHeaderNav className="d-flex me-auto">
                    <CHeaderToggler className="ps-1" onClick={toggleSidebarDesktop}>
                        <CIcon icon={cilMenu} size="lg"/>
                    </CHeaderToggler>
                </CHeaderNav>
            </CContainer>
            <CHeaderDivider/>
            <CContainer fluid>
                <AppBreadcrumb/>
            </CContainer>
        </CHeader>
    );
};

export default TheHeader;
