import {createAsyncThunk} from '@reduxjs/toolkit';
import {pickBy} from 'lodash';
import {IParams} from "../../../shared/shared-interfaces";
import axios from '../../../shared/config/axios-interceptor';
import {IGroup} from "../../../shared/model/group.model";

const prefix = 'groups';
export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (body: IParams, thunkAPI) => {
    try {
        const params = pickBy(body);
        return await axios.get<IGroup[]>(`admin/${prefix}`, {params});
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const getEntity = createAsyncThunk(`get-single-${prefix}`, async (group_id: string, thunkAPI) => {
    try {
        const {data} = await axios.get<IGroup>(`${prefix}/${group_id}`);
        return data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const updateEntity = createAsyncThunk(`update-one-${prefix}`, async (body: IGroup, thunkAPI) => {
    try {
        const {group_id} = body;
        const {data} = await axios.put<IGroup>(`admin/${prefix}/${group_id}`, body);
        return data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const createEntity = createAsyncThunk(`create-one-${prefix}`, async (body: IGroup, thunkAPI) => {
    try {
        const {data} = await axios.post(`admin/${prefix}`, body);
        return data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const removeEntity = createAsyncThunk(`delete-one-${prefix}`, async (id: string, thunkAPI) => {
    try {
        await axios.delete(`admin/${prefix}/${id}`);
        return id;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const getSiteActive =  createAsyncThunk(`get-siteactive-${prefix}`, async (body:any, thunkAPI) => {
    try {
        return await axios.get(`admin/${prefix}/sites`);
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});