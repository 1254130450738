import React from 'react';
import {Navigate, RouteObject, useRoutes} from 'react-router-dom';

const Site = React.lazy(() => import('./Site'));

export const siteLayout: RouteObject[] = [
    {path: '/', element: <Site/>},
    {path: '*', element: <Navigate to="/404"/>},
];

const SiteRoutes = () => useRoutes(siteLayout);
export default SiteRoutes;
