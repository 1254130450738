import {CContainer} from '@coreui/react-pro';
import React, {Suspense, useEffect} from 'react';
import {RouteRender} from '../../routes';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../reducers";
import {fetching} from "../modules/auth/auth.reducer";
import {account} from "../modules/auth/auth.api";

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
);

const TheContent = () => {
    const dispatch = useDispatch();
    const {token} = useSelector((state: RootState) => state.authentication);
    useEffect(() => {
        let tempToken = token;
        if (!tempToken) {
            tempToken = localStorage.getItem('authentication_token');
        }

        if (tempToken) {
            // get current user info
            dispatch(fetching());
            dispatch(account());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    return (
        <CContainer fluid className="px-0">
            <Suspense fallback={loading}>
                <RouteRender/>
            </Suspense>
        </CContainer>
    );
};

export default React.memo(TheContent);
