import React, {useEffect, useState} from 'react';
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CFormFeedback,
    CFormInput,
    CFormLabel,
    CRow
} from "@coreui/react-pro";
import {Formik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../reducers";
import {ToastError, ToastSuccess} from "../shared/toast/Toast";
import {fetching, resetAll, resetChangePassword} from "./auth/auth.reducer";
import {changepassword} from "./auth/auth.api";


const ChangePassword = () => {
    const dispatch = useDispatch();
    const {errorMessage, changePasswordSuccess} = useSelector((state: RootState) => state.authentication);
    const initialValues = {password: '', newpassword: '', newpassword2: ''};

    useEffect(() => {
        if (errorMessage) {
            ToastError('Current password is incorrect!');
            dispatch(resetChangePassword())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorMessage]);

    useEffect(() => {
        if (changePasswordSuccess) {
            ToastSuccess('Your password has been updated successfully!');
            dispatch(resetChangePassword())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changePasswordSuccess]);

    return (
        <>
            <CRow className="justify-content-center mb-3">
                <CCol xs={12} sm={12} md={6} lg={6}>
                    <CCardGroup>
                        <CCard className="">
                            <CCardBody>
                                <h5>Change your password</h5>
                                <Formik
                                    initialValues={initialValues}
                                    validate={values => {
                                        const errors: any = {};
                                        if (!values.password) {
                                            errors.password = 'The current password field is empty.';
                                        }
                                        if (!values.newpassword) {
                                            errors.newpassword = 'The new password field is empty.';
                                        }
                                        if (!values.newpassword2) {
                                            errors.newpassword2 = 'The confirm new password field is empty.';
                                        }
                                        if (values.newpassword != values.newpassword2) {
                                            errors.newpassword2 = 'The confirm new password field is invalid.';
                                        }
                                        return errors;
                                    }}
                                    onSubmit={(values, {resetForm}) => {
                                        dispatch(fetching());
                                        dispatch(changepassword(values));
                                        resetForm();
                                    }}
                                >
                                    {({
                                          values,
                                          errors,
                                          touched,
                                          handleChange,
                                          handleBlur,
                                          handleSubmit,
                                      }) => (
                                        <form onSubmit={handleSubmit}>
                                            <CRow className="justify-content-center mb-3">
                                                <CCol xs={12} sm={12} md={12} lg={12}>
                                                    <div className="mb-3">
                                                        <CFormLabel htmlFor="name">Current password</CFormLabel>
                                                        <CFormInput
                                                            type="password"
                                                            id="password"
                                                            value={values.password}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            invalid={!!errors.password && touched.password}
                                                        />
                                                        <CFormFeedback invalid
                                                                       className={!!errors.password && touched.password ? 'd-block' : 'd-none'}>
                                                            {errors.password}
                                                        </CFormFeedback>
                                                    </div>
                                                    <div className="mb-3">
                                                        <CFormLabel htmlFor="name">New password</CFormLabel>
                                                        <CFormInput
                                                            type="password"
                                                            id="newpassword"
                                                            value={values.newpassword}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            invalid={!!errors.newpassword && touched.newpassword}
                                                        />
                                                        <CFormFeedback invalid
                                                                       className={!!errors.newpassword && touched.newpassword ? 'd-block' : 'd-none'}>
                                                            {errors.newpassword}
                                                        </CFormFeedback>
                                                    </div>
                                                    <div className="mb-3">
                                                        <CFormLabel htmlFor="name">Confirm new password</CFormLabel>
                                                        <CFormInput
                                                            type="password"
                                                            id="newpassword2"
                                                            value={values.newpassword2}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            invalid={!!errors.newpassword2 && touched.newpassword2}
                                                        />
                                                        <CFormFeedback invalid
                                                                       className={!!errors.newpassword2 && touched.newpassword2 ? 'd-block' : 'd-none'}>
                                                            {errors.newpassword2}
                                                        </CFormFeedback>
                                                    </div>
                                                </CCol>
                                            </CRow>
                                            <CRow className="justify-content-end mb-3">
                                                <CCol xs={"auto"}>
                                                    <CButton color="primary" className="text-white" type="submit">
                                                        Save
                                                    </CButton>
                                                </CCol>
                                            </CRow>
                                        </form>
                                    )}
                                </Formik>
                            </CCardBody>
                        </CCard>
                    </CCardGroup>
                </CCol>
            </CRow>
        </>
    );
};

export default ChangePassword;
