import React from 'react';
import {Navigate, RouteObject, useRoutes} from 'react-router-dom';
import AuthRoutes from './components/modules/auth/routes';
import SiteRoutes from './components/modules/site/routes';
import Dashboard from "./components/modules/Dashboard";
import GroupRoutes from "./components/modules/group/routes";
import ChangePassword from "./components/modules/ChangePassword";

// Error pages

const Page404 = React.lazy(() => import('./components/modules/Page404'));
const Page500 = React.lazy(() => import('./components/modules/Page500'));
const Page403 = React.lazy(() => import('./components/modules/Page403'));
const routes: RouteObject[] = [
    {path: '/', element: <Navigate to="/dashboard"/>},
    {path: '/404', element: <Page404/>},
    {path: '/500', element: <Page500/>},
    {path: '/403', element: <Page403/>},
    {path: '/auth/*', element: <AuthRoutes/>},
    {path: '/site/*', element: <SiteRoutes/>},
    {path: '/group/*', element: <GroupRoutes/>},
    {path: 'dashboard', element: <Dashboard/>},
    {path: 'change-password', element: <ChangePassword/>},
    {path: '*', element: <Navigate to="/404"/>},
];

const RouteRender = () => useRoutes(routes);

export {RouteRender, routes};

