import React from 'react';
import {Navigate, RouteObject, useRoutes} from 'react-router-dom';

const Group = React.lazy(() => import('./Group'));

export const groupLayout: RouteObject[] = [
    {path: '/', element: <Group/>},
    {path: '*', element: <Navigate to="/404"/>},
];

const GroupRoutes = () => useRoutes(groupLayout);
export default GroupRoutes;
