import {combineReducers} from 'redux';
import container from './components/containers/reducer';
import authentication from './components/modules/auth/auth.reducer';
import siteReducer from './components/modules/site/site.reducer';
import groupReducer from './components/modules/group/group.reducer';

const rootReducer = combineReducers({container, authentication, siteReducer, groupReducer});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
