import React from 'react';
import {Navigate, RouteObject, useRoutes} from 'react-router-dom';

const Login = React.lazy(() => import('./Login'));

export const authLayout: RouteObject[] = [
    {path: 'login', element: <Login/>},
    {path: '*', element: <Navigate to="/404"/>},
];

const AuthRoutes = () => useRoutes(authLayout);
export default AuthRoutes;
