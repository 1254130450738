import {CFooter} from '@coreui/react-pro';
import React from 'react';

const TheFooter = () => {
    return (
        <CFooter>
            <div className="ms-auto">
                <a href="https://brytend.eu/" target="_blank" rel="noopener noreferrer">
                    Chat Service
                </a>
                <span className="ms-1">&copy; 2022 Brytend B.V.</span>
            </div>
        </CFooter>
    );
};

export default React.memo(TheFooter);
