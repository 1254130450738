import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { RootState } from '../../../reducers';
import { IInitialState, IResponse } from '../../../shared/shared-interfaces';
import { createEntity, getEntities, getEntity, removeEntity, updateEntity } from './site.api';
import {ISite} from "../../../shared/model/site.model";

export const siteAdapter = createEntityAdapter<ISite>({
    selectId: ({ site_id }) => site_id,
});

const initialState: IInitialState = {
    fetchEntitiesSuccess: false,
    fetchEntitySuccess: false,
    createEntitySuccess: false,
    updateEntitySuccess: false,
    deleteEntitySuccess: false,
    loading: false,
    errorMessage: null,
    totalItems: 0,
    page: 0,
    size: 0,
    maxPage: 0
};

const { actions, reducer } = createSlice({
    name: 'departmentSlice',
    initialState: siteAdapter.getInitialState({ initialState }),
    reducers: {
        fetching(state) {
            state.initialState.loading = true;
        },
        resetAll(state) {
            state.initialState.loading = false;
            state.initialState.fetchEntitiesSuccess = false;
            state.initialState.fetchEntitySuccess = false;
            state.initialState.updateEntitySuccess = false;
            state.initialState.deleteEntitySuccess = false;
            state.initialState.errorMessage = null;
        },
        resetEntity(state) {
            state.initialState.updateEntitySuccess = false;
            state.initialState.errorMessage = null;
            state.initialState.deleteEntitySuccess = false;
        },
    },
    extraReducers: {
        [getEntities.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<IResponse<ISite[]>>>) => {
            siteAdapter.setAll(state, payload.data.items);
            state.initialState.totalItems = Number(payload.data.totalItems);
            state.initialState.page = Number(payload.data.page);
            state.initialState.size = Number(payload.data.size);
            state.initialState.maxPage = Number(payload.data.maxPage);
            state.initialState.fetchEntitiesSuccess = true;
            state.initialState.loading = false;
        },
        [getEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
            state.initialState.errorMessage = payload?.message;
            state.initialState.loading = false;
            state.initialState.fetchEntitiesSuccess = false;
        },
        [getEntity.fulfilled.type]: (state, { payload }: PayloadAction<ISite>) => {
            siteAdapter.upsertOne(state, payload);
            state.initialState.fetchEntitySuccess = true;
            state.initialState.loading = false;
        },
        [getEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
            state.initialState.errorMessage = payload?.message;
            state.initialState.loading = false;
            state.initialState.fetchEntitySuccess = false;
        },
        [updateEntity.fulfilled.type]: (state, { payload }: PayloadAction<ISite>) => {
            siteAdapter.updateOne(state, { id: payload.site_id, changes: payload });
            state.initialState.updateEntitySuccess = true;
            state.initialState.loading = false;
        },
        [updateEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
            state.initialState.errorMessage = payload?.message;
            state.initialState.loading = false;
            state.initialState.updateEntitySuccess = false;
        },
        [createEntity.fulfilled.type]: (state, { payload }: PayloadAction<ISite>) => {
            siteAdapter.addOne(state, payload);
            state.initialState.createEntitySuccess = true;
            state.initialState.loading = false;
        },
        [createEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
            state.initialState.errorMessage = payload?.message;
            state.initialState.loading = false;
            state.initialState.createEntitySuccess = false;
        },
        [removeEntity.fulfilled.type]: (state, { payload }: PayloadAction<string>) => {
            siteAdapter.removeOne(state, payload);
            state.initialState.totalItems -= 1;
            state.initialState.deleteEntitySuccess = true;
            state.initialState.loading = false;
        },
        [removeEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
            state.initialState.errorMessage = payload?.message;
            state.initialState.loading = false;
            state.initialState.deleteEntitySuccess = false;
        }
    },
});

export default reducer;

export const { fetching, resetAll, resetEntity } = actions;
export const siteSelectors = siteAdapter.getSelectors((state: RootState) => state.siteReducer);

const getSiteState = (state: RootState) => state.siteReducer;
const { selectById } = siteAdapter.getSelectors();

export const selectEntityById = (id: string) => {
    return createSelector(getSiteState, (state) => selectById(state, id));
};
