import {Provider} from 'react-redux';
import {HashRouter} from 'react-router-dom';
import ErrorBoundary from './components/containers/ErrorBoundary';
import TheLayout from './components/containers/TheLayout';
import store from './store';
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
    return (
        <ErrorBoundary>
            <Provider store={store}>
                <HashRouter>
                    <ToastContainer
                        position={toast.POSITION.TOP_LEFT}
                        className="toastify-container"
                        toastClassName="toastify-toast"
                    />
                    <TheLayout/>
                </HashRouter>
            </Provider>
        </ErrorBoundary>
    );
};

export default App;
