import {cilAccountLogout, cilKey, cilStar} from '@coreui/icons-pro';
import CIcon from '@coreui/icons-react';
import {CNavItem, CNavTitle, CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler} from '@coreui/react-pro';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../reducers';
import {toggleSidebar} from './reducer';
import {logout} from "../modules/auth/auth.reducer";
import {useRouter} from "../../shared/utils/ultils";

const TheSidebar = () => {
    const dispatch = useDispatch();
    const {navigate} = useRouter();

    const containerState = useSelector((state: RootState) => state.container);
    const {sidebarShow} = containerState;
    const [unfoldable, setUnfoldable] = useState<boolean>(false);
    const handleLogout = () => {
        dispatch(logout());
        navigate('/auth/login');
    };
    return (
        <CSidebar
            position="fixed"
            visible={sidebarShow}
            unfoldable={unfoldable}
            onVisibleChange={(val: boolean) => dispatch(toggleSidebar(val))}
        >
            <CSidebarBrand>Chat service</CSidebarBrand>
            <CSidebarNav>
                <CNavItem href="#/dashboard">
                    <CIcon customClassName="nav-icon" icon={cilStar}/>
                    Dashboard
                </CNavItem>
                <CNavItem href="#/site">
                    <CIcon customClassName="nav-icon" icon={cilStar}/>
                    Site
                </CNavItem>
                <CNavItem href="#/group">
                    <CIcon customClassName="nav-icon" icon={cilStar}/>
                    Group
                </CNavItem>
                <CNavTitle>Account</CNavTitle>
                <CNavItem href="#/change-password">
                    <CIcon customClassName="nav-icon" icon={cilKey}/>
                    Change password
                </CNavItem>
                <CNavItem href="#" onClick={handleLogout}>
                    <CIcon customClassName="nav-icon" icon={cilAccountLogout}/>
                    Logout
                </CNavItem>
            </CSidebarNav>
            <CSidebarToggler className="d-none d-lg-flex" onClick={() => setUnfoldable(!unfoldable)}/>
        </CSidebar>
    );
};

export default React.memo(TheSidebar);
