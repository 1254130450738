import React from 'react';
import {useLocation} from 'react-router-dom';
import TheContent from './TheContent';
import TheFooter from './TheFooter';
import TheHeader from './TheHeader';
import TheSidebar from './TheSidebar';
import {RequireAuth} from "../modules/auth/RequireAuth";


const TheLayout = () => {
    const location = useLocation()
    const isAuthView = location.pathname.includes("auth")
    if (isAuthView) {
        return (<TheContent/>);
    }
    return (
        <RequireAuth>
            <TheSidebar/>
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                <TheHeader/>
                <div className="body flex-grow-1 px-3">
                    <TheContent/>
                </div>
                <TheFooter/>
            </div>
        </RequireAuth>
    );
};
export default TheLayout;
