import {createAsyncThunk} from '@reduxjs/toolkit';
import {pickBy} from 'lodash';
import axios from '../../../shared/config/axios-interceptor';


export interface ILoginForm {
    username: string;
    password: string;
    rememberMe: boolean;
}

export interface IChangePasswordForm {
    password: string;
    newpassword: string;
}

export const login = createAsyncThunk(`admin-authenticate`, async (body: ILoginForm, thunkAPI) => {
    try {
        const {data} = await axios.post(`admin-authenticate`, pickBy(body));
        return data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const account = createAsyncThunk(`admin-account`, async (_, thunkAPI) => {
    try {
        const {data} = await axios.get(`admin-account`);
        return data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});


export const changepassword = createAsyncThunk(`admin-change-password`, async (body: IChangePasswordForm, thunkAPI) => {
    try {
        const {data} = await axios.post(`admin-account-password`, pickBy(body));
        return data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});